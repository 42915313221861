import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';
import { IDEAApiService, IDEAStorageService } from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { AppService } from './app.service';

import { Team } from '@models/team.model';
import { Membership } from '@models/membership.model';
import { UserPreferences } from '@models/userPreferences.model';
import { CognitoUser } from 'idea-toolbox';

export const authGuard: CanActivateFn = async (
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> => {
  const platform = inject(Platform);
  const navCtrl = inject(NavController);
  const storage = inject(IDEAStorageService);
  const api = inject(IDEAApiService);
  const auth = inject(IDEAAuthService);
  const app = inject(AppService);

  if (app.membershipReady) return true;

  //
  // HELPERS
  //

  const doAuth = async (): Promise<boolean> => {
    const authRes = await auth.isAuthenticated(false, freshIdToken => (api.authToken = freshIdToken));
    api.authToken = authRes.idToken;
    app.user = new CognitoUser(authRes.userDetails);

    try {
      const teamId = window.location.pathname?.startsWith('/teams/') ? window.location.pathname?.split('/')[2] : null;
      await loadMembershipData(teamId);
      platform.resume.subscribe((): Promise<void> => loadMembershipData());

      if (!app.team && !window.location.pathname?.startsWith('/select-team')) navigateAndResolve(['select-team']);
      else {
        app.membershipReady = true;
        if (window.location.pathname === '/') navigateAndResolve([]);
      }
    } catch (err) {
      return navigateAndResolve(['intro']);
    }
  };

  const navigateAndResolve = (navigationPath?: string[]): boolean => {
    if (navigationPath) navCtrl.navigateRoot(navigationPath);
    app.authReady = true;
    return true;
  };

  const loadMembershipData = async (teamId?: string): Promise<void> => {
    const preferences = await api.getResource('preferences', { params: { startUp: teamId ?? 'current' } });
    app.preferences = new UserPreferences(preferences);
    if (preferences.team) {
      app.team = new Team(preferences.team);
      app.membership = new Membership(preferences.membership);
    }
  };

  //
  // MAIN
  //

  if (app.authReady) return true;

  await platform.ready();
  await storage.ready();

  try {
    await doAuth();
    platform.resume.subscribe((): Promise<boolean> => doAuth());

    if (!app.team && !window.location.pathname?.startsWith('/select-team')) return navigateAndResolve(['select-team']);
    else if (window.location.pathname === '/') return navigateAndResolve([]);
    else return navigateAndResolve();
  } catch (error) {
    return navigateAndResolve(['auth']);
  }
};
