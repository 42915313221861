<ion-app>
  <ion-split-pane
    contentId="main"
    when="md"
    [disabled]="shouldHideSplitPane() || isPageWithoutMenu()"
    [class.desktopMode]="isDesktopMode()"
  >
    <ion-menu id="theMenu" contentId="main" [disabled]="isPageWithoutMenu()">
      <ion-header class="ion-no-border">
        <ion-toolbar [color]="app.isInDarkMode() ? 'transparent' : 'ideaToolbar'">
          <p *ngIf="!isDesktopMode()"><ion-menu-button></ion-menu-button></p>
          <ion-menu-toggle
            auto-hide="false"
            class="tappable"
            *ngIf="app.membership"
            (click)="app.goToInTeam(['memberships', 'profile'])"
          >
            <idea-user-avatar
              [src]="app.getUserAvatar()"
              [name]="app.membership.name"
              [size]="isDesktopMode() ? 'default' : 'large'"
            ></idea-user-avatar>
            <h3 class="ion-text-center" *ngIf="!isDesktopMode()">{{ app.membership.name }}</h3>
          </ion-menu-toggle>
        </ion-toolbar>
      </ion-header>
      <ion-content [color]="app.isInDarkMode() ? 'transparent' : 'ideaToolbar'">
        <ion-list>
          <ng-container *ngFor="let page of menuPages">
            <ng-container *ngIf="isPageVisible(page)">
              <ion-item-divider *ngIf="page.hasHeader" [color]="app.isInDarkMode() ? 'transparent' : 'ideaToolbar'">
                <ion-label *ngIf="!isDesktopMode()" [color]="app.isInDarkMode() ? 'medium' : 'light'">
                  {{ page.hasHeader | translate }}
                </ion-label>
              </ion-item-divider>
              <ion-menu-toggle auto-hide="false">
                <ion-button
                  expand="full"
                  fill="clear"
                  *ngIf="isDesktopMode()"
                  [color]="app.isInDarkMode() ? 'primary' : 'light'"
                  [title]="page.title.concat('_I') | translate"
                  (click)="handleMenuClick(page)"
                >
                  <ion-icon slot="icon-only" [icon]="page.icon"></ion-icon>
                </ion-button>
                <ion-item
                  lines="none"
                  *ngIf="!isDesktopMode()"
                  button
                  [color]="app.isInDarkMode() ? 'transparent' : 'tertiary'"
                  [title]="page.title.concat('_I') | translate"
                  (click)="handleMenuClick(page)"
                >
                  <ion-label [color]="app.isInDarkMode() ? 'dark' : 'light'"> {{ page.title | translate }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ng-container>
          </ng-container>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
