import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { CognitoUser } from 'idea-toolbox';
import { IDEAApiService, IDEAMessageService, IDEAStorageService, IDEATranslationsService } from '@idea-ionic/common';

import { environment as env } from '@env';

import { UserPreferences } from '@models/userPreferences.model';
import { Team } from '@models/team.model';
import { Membership } from '@models/membership.model';
import { IDEAAuthService } from '@idea-ionic/auth';

/**
 * The base URLs where the thumbnails are located.
 */
const THUMBNAILS_BASE_URL = env.idea.app.mediaUrl.concat('/images/', env.idea.api.stage, '/');
/**
 * A local fallback URL for the app's images.
 */
const IMAGE_FALLBACK_URL = './assets/imgs/no-image.jpg';

@Injectable({ providedIn: 'root' })
export class AppService {
  initReady = false;
  authReady = false;
  membershipReady = false;

  private darkMode: boolean;

  preferences: UserPreferences;
  team: Team;
  membership: Membership;
  user: CognitoUser;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private message: IDEAMessageService,
    private storage: IDEAStorageService,
    private auth: IDEAAuthService,
    private api: IDEAApiService,
    private t: IDEATranslationsService
  ) {
    this.darkMode = this.respondToColorSchemePreferenceChanges();
  }
  private respondToColorSchemePreferenceChanges(): boolean {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => (this.darkMode = e.matches));
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  /**
   * Whether we are running the app in developer mode (from localhost).
   */
  isDeveloperMode(): boolean {
    return env.debug;
  }
  /**
   * Open an alert to get the token for running requests against this project's API.
   */
  async getTokenId(): Promise<void> {
    const message = typeof this.api.authToken === 'function' ? await this.api.authToken() : this.api.authToken;
    const alert = await this.alertCtrl.create({ message, buttons: ['Thanks 🙌'], cssClass: 'selectable' });
    alert.present();
  }

  /**
   * Whether we should display a UX designed for smaller screens.
   */
  isInMobileMode(): boolean {
    return this.platform.width() < 768;
  }
  /**
   * Whether the current color scheme preference is set to dark.
   */
  isInDarkMode(): boolean {
    return this.darkMode;
  }

  /**
   * Reload the app.
   */
  reloadApp(): void {
    window.location.assign('');
  }
  /**
   * Navigate to a page by its path.
   */
  async goTo(path: string[], options: { back?: boolean; root?: boolean; queryParams?: Params } = {}): Promise<void> {
    if (options.back) await this.navCtrl.navigateBack(path, options);
    else if (options.root) await this.navCtrl.navigateRoot(path, options);
    else await this.navCtrl.navigateForward(path, options);
  }
  /**
   * Navigate to a page inside the team hierarchy by its path.
   */
  async goToInTeam(
    path: string[],
    options: { back?: boolean; root?: boolean; queryParams?: Params } = {}
  ): Promise<void> {
    path = ['teams', this.membership ? this.membership.teamId : 'current', ...path];
    await this.goTo(path, options);
  }
  /**
   * Close the current page and navigate back, optionally displaying an error message.
   */
  async closePage(errorMessage?: string, pathBack?: string[]): Promise<void> {
    if (errorMessage) await this.message.error(errorMessage);
    if (pathBack) await this.navCtrl.navigateBack(pathBack);
    else this.navCtrl.back();
  }

  /**
   * Whether we should show the mobile UI.
   */
  isMobile(): boolean {
    return this.platform.width() < 768;
  }

  /**
   * Get the URL to the provided image URI.
   */
  getImageURL(imageURI: string, useFallback = true): string {
    if (!imageURI) return useFallback ? IMAGE_FALLBACK_URL : null;
    return THUMBNAILS_BASE_URL.concat(imageURI, '.png');
  }
  /**
   * Load a fallback URL when the image is missing.
   */
  fallbackImage(targetImg: any): void {
    if (targetImg && targetImg.src !== IMAGE_FALLBACK_URL) targetImg.src = IMAGE_FALLBACK_URL;
  }
  /**
   * Get the URL to the user's profile image (avatar).
   */
  getUserAvatar(membership?: Membership): string {
    const { avatarURI } = membership ?? this.membership ?? {};
    return this.getImageURL(avatarURI, false);
  }

  /**
   * Get the app's main logo.
   */
  getLogo(withBg = false): string {
    const addBg = withBg ? '-bg' : '';
    return `/assets/icons/icon${addBg}.svg`;
  }

  /**
   * Show some app's info.
   */
  async info(): Promise<void> {
    const openPrivacyPolicy = (): Promise<void> => Browser.open({ url: this.t._('IDEA_VARIABLES.PRIVACY_POLICY_URL') });

    const header = this.t._('COMMON.APP_NAME');
    const message = this.t._('COMMON.VERSION', { v: env.idea.app.version });
    const buttons = [
      { text: this.t._('IDEA_AUTH.PRIVACY_POLICY'), handler: openPrivacyPolicy },
      { text: this.t._('COMMON.CLOSE'), role: 'cancel' }
    ];

    const alert = await this.alertCtrl.create({ header, message, buttons });
    alert.present();
  }

  /**
   * Sign-out from the current user.
   */
  async logout(skipAlert = false): Promise<void> {
    const doLogout = async (): Promise<void> => {
      await this.auth.logout();
      await this.storage.clear();
      this.reloadApp();
    };

    if (skipAlert) return doLogout();

    const header = this.t._('COMMON.LOGOUT');
    const message = this.t._('COMMON.ARE_YOU_SURE');
    const buttons = [
      { text: this.t._('COMMON.CANCEL'), role: 'cancel' },
      { text: this.t._('COMMON.LOGOUT'), handler: doLogout }
    ];

    const alert = await this.alertCtrl.create({ header, message, buttons });
    alert.present();
  }
}
