import { Resource } from 'idea-toolbox';

/**
 * The users member of a team.
 */
export class Membership extends Resource {
  /**
   * The ID of the team.
   */
  teamId: string;
  /**
   * The ID of the user.
   */
  userId: string;
  /**
   * The name of the user.
   */
  name: string;
  /**
   * The URI of the user's avatar in the team.
   */
  avatarURI: string;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.userId = this.clean(x.userId, String);
    this.name = this.clean(x.name, String);
    this.avatarURI = this.clean(x.avatarURI, String);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.userId = safeData.userId;
  }
}
