import { Resource } from 'idea-toolbox';

/**
 * The preferences of a user of the platform.
 */
export class UserPreferences extends Resource {
  /**
   * The ID of the user.
   */
  userId: string;
  /**
   * The ID of the selected team.
   */
  selectedTeamId: string;

  load(x: any): void {
    super.load(x);
    this.userId = this.clean(x.userId, String);
    this.selectedTeamId = this.clean(x.selectedTeamId, String);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.userId = safeData.userId;
  }
}
