import { Resource } from 'idea-toolbox';

/**
 * A team using the platform to allow the booking of its spaces and rooms.
 */
export class Team extends Resource {
  /**
   * The ID of the team.
   */
  teamId: string;
  /**
   * The name of the team.
   */
  name: string;
  /**
   * The size of the slots chosen by the team.
   * It should not be changed.
   */
  slotSize: SlotSizes;
  /**
   * The IDs of this team's managers.
   */
  managers: string[];
  /**
   * The maximum number of additional bookings a user can perform in each slot.
   */
  maxNumOfAdditionalBookingsInSlot: number;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.name = this.clean(x.name, String);
    this.slotSize = this.clean(x.slotSize, Number, SlotSizes.THIRTY_MINUTES);
    this.managers = this.cleanArray(x.managers, String);
    this.maxNumOfAdditionalBookingsInSlot = this.clean(x.maxNumOfAdditionalBookingsInSlot, Number, 0);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.slotSize = safeData.slotSize;
    this.managers = safeData.managers;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.name)) e.push('name');
    if (!(this.slotSize in SlotSizes)) e.push('slotSize');
    if (this.iE(this.managers)) e.push('managers');
    return e;
  }

  /**
   * Whether the user can manage the team.
   */
  canUserManage({ userId }: { userId: string }): boolean {
    return this.managers.includes(userId);
  }

  /**
   * Get the list of valid minutes for slots, based on the team's configuration.
   */
  getValidSlotMinutes(): number[] {
    if (this.slotSize === SlotSizes.FIFTEEN_MINUTES) return [0, 15, 30, 45];
    if (this.slotSize === SlotSizes.THIRTY_MINUTES) return [0, 30];
    if (this.slotSize === SlotSizes.ONE_HOUR) return [0];
    else return [];
  }
}

/**
 * The possible slot sizes. A team must choose one.
 */
export enum SlotSizes {
  FIFTEEN_MINUTES = 4,
  THIRTY_MINUTES = 2,
  ONE_HOUR = 1
}

/**
 * The invitation for a user to join a team.
 */
export interface TeamInvitation {
  /**
   * The ID of the invitation.
   */
  invitationId: string;
  /**
   * The email of the invited user.
   */
  userEmail: string;
  /**
   * The full name of the invited user.
   */
  userName: string;
  /**
   * The ID of the team to which the user was invited.
   */
  teamId: string;
  /**
   * The timestamp (in seconds) when the invitation will expire.
   */
  expiresAt: number;
}
