import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from './app.service';

const PAGES_WITHOUT_INTRO = ['intro', 'select-team', 'invitations'];

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  menuPages: MenuPage[] = [
    { title: 'MENU.DASHBOARD', navigationPathInTeam: ['dashboard'], icon: 'compass', hasHeader: 'MENU.MENU' },
    { title: 'MENU.TEAMS', navigationPath: ['select-team'], icon: 'swap-horizontal' },
    { title: 'MENU.MEMBERSHIPS', navigationPathInTeam: ['memberships'], icon: 'people', visibleOn: 'isTeamManager' },
    { title: 'MENU.SPACES', navigationPathInTeam: ['spaces'], icon: 'business', visibleOn: 'isTeamManager' },
    { title: 'MENU.SETTINGS', navigationPathInTeam: ['settings'], icon: 'settings', visibleOn: 'isTeamManager' },
    { title: 'MENU.KPI', navigationPathInTeam: ['statistics'], icon: 'stats-chart', visibleOn: 'isTeamManager' },
    { title: 'MENU.INFO', method: 'info', icon: 'information-circle', hasHeader: 'MENU.ACTIONS' },
    { title: 'MENU.INTRO', navigationPath: ['intro'], icon: 'help-buoy' },
    { title: 'MENU.LOGOUT', method: 'logout', icon: 'log-out' }
  ];

  constructor(private router: Router, public app: AppService) {}

  isPageVisible(p: MenuPage): boolean {
    return !p.visibleOn || (this as any)[p.visibleOn]();
  }
  handleMenuClick(page: MenuPage): void {
    if (page.method && (this as any)[page.method]) (this as any)[page.method]();
    else if (page.navigationPathInTeam) this.app.goToInTeam(page.navigationPathInTeam);
    else if (page.navigationPath) this.app.goTo(page.navigationPath);
  }

  info(): void {
    this.app.info();
  }
  logout(): void {
    this.app.logout();
  }
  isTeamManager(): boolean {
    return this.app.team?.canUserManage(this.app?.user);
  }

  shouldHideSplitPane(): boolean {
    return !this.app.user;
  }
  isDesktopMode(): boolean {
    return !this.app.isMobile();
  }
  isPageWithoutMenu(): boolean {
    return !this.router.url || PAGES_WITHOUT_INTRO.some(x => this.router.url.split('?')[0] === '/'.concat(x));
  }
}

interface MenuPage {
  /**
   * The title of the button; a title_I will be automatically added as tooltip.
   */
  title?: string;
  /**
   * The url to navigate to on click.
   */
  navigationPath?: string[];
  /**
   * The url to navigate to on click (inside the team's routing). Precedence over `navigationPath`.
   */
  navigationPathInTeam?: string[];
  /**
   * The name of the component method to run on click. Precedence over `navigationPathInTeam`.
   */
  method?: string;
  /**
   * IonIcon code.
   */
  icon: string;
  /**
   * If set, prepone a header before the item.
   */
  hasHeader?: string;
  /**
   * If set, represents the name of the component method to check if the action can be seen.
   */
  visibleOn?: string;
}
