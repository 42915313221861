import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { initGuard } from './init.guard';
import { authGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'teams/current', pathMatch: 'full' },
  {
    path: 'intro',
    loadChildren: (): Promise<any> => import('./intro/intro.module').then(m => m.IntroModule),
    canActivate: [initGuard]
  },
  {
    path: 'auth',
    loadChildren: (): Promise<any> => import('@idea-ionic/auth').then(m => m.IDEAAuthModule),
    canActivate: [initGuard]
  },
  {
    path: 'invitations',
    loadChildren: (): Promise<any> => import('./invitations/invitations.module').then(m => m.InvitationsModule),
    canActivate: [initGuard]
  },
  {
    path: 'select-team',
    loadChildren: (): Promise<any> => import('./selectTeam/selectTeam.module').then(m => m.SelectTeamModule),
    canActivate: [authGuard]
  },
  {
    path: 'teams/:teamId',
    loadChildren: (): Promise<any> => import('./teams/teams.module').then(m => m.TeamsModule),
    canActivate: [initGuard, authGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: 'always' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
