/**
 * Variables to configure an ITER IDEA's cloud app, together with its inner modules.
 */
export const environment = {
  idea: {
    project: 'slots',
    ionicExtraModules: ['common', 'agenda', 'auth'],
    app: {
      version: '1.2.1',
      url: 'slots-app.link',
      mediaUrl: 'https://media.slots-app.link',
      bundle: 'com.iteridea.slots'
    },
    api: {
      url: 'api.slots-app.link',
      stage: 'dev'
    },
    auth: {
      title: 'Slots',
      website: 'https://iter-idea.com',
      hasIntroPage: true,
      registrationIsPossible: false,
      singleSimultaneousSession: false,
      forceLoginWithMFA: false,
      passwordPolicy: {
        minLength: 8,
        requireLowercase: false,
        requireDigits: false,
        requireSymbols: false,
        requireUppercase: false
      }
    }
  },
  aws: {
    cognito: {
      userPoolId: 'eu-south-1_XJ5fAz9J7',
      userPoolClientId: '3qhj7o23ol7r0575s7hg14g7mk'
    }
  }
};
